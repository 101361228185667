import axios from "axios";
import { BASE_URL } from '../utility/UrlConstants';
import { getBearerToken, getHeaderConfig } from '../utility/HeaderConfig';

const ProductCateCountApi = (params: Object) => {
  // axios.defaults.headers.common.token = `Bearer ${ getBearerToken()}`;
  return axios
    .get(
      `${BASE_URL}/products/category/metadata/get-all?metaProdCatFilter=${JSON.stringify(
        params
      )}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const getPageProductCate = (size : Number , searchValue: Object) => {
    return axios
      .get(`${BASE_URL}/products/category/metadata/get-page?page=0&size=${size}&sort=createdAt,desc&metaProdCateFilter=${JSON.stringify(
        searchValue
      )}`, getHeaderConfig())
      .then((dataList) => {
        const respData = {
          responseCode: dataList.status,
          response: dataList.data,
        };
        return respData;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };


  const getAllProductCate = (searchValue: Object) => {
    return axios
      .get(`${BASE_URL}/products/category/metadata/get-all?metaProdCatFilter=${JSON.stringify(
        searchValue
      )}`, getHeaderConfig())
      .then((dataList) => {
        const respData = {
          responseCode: dataList.status,
          response: dataList.data,
        };
        return respData;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  const getProductCate = (searchValue: Object) => {
    return axios
      .get(`${BASE_URL}/products/productlabel/metadata/get-page?page=0&size=2000&sort=createdAt,desc&filters=${JSON.stringify(
        searchValue
      )}`, getHeaderConfig())
      .then((dataList) => {
        const respData = {
          responseCode: dataList.status,
          response: dataList.data,
        };
        return respData;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  
export { getPageProductCate , ProductCateCountApi , getAllProductCate,getProductCate };
